@use "src/styles/typography";

$button-border-width: 2px;
$transition-fast: 0.2s;
$transition-slow: 0.3s;

.button {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 10px;
  transition: $transition-fast;

  &__full-width {
    width: 100%;
  }

  &__loading-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 0.5em;
  }

  &__very-small,
  &__small,
  &__middle,
  &__big {
    box-sizing: border-box;
  }

  &__very-small {
    @include typography.button-small;
    padding: 8px 12px;
  }
  &__small {
    @include typography.button-small;
    padding: 10px 12px;
  }
  &__middle {
    @include typography.button-middle;
    padding: 12px 14px;
  }
  &__big {
    @include typography.button-big;
    padding: 14px 16px;
  }

  $colors: blue, blue-dark, green, red, yellow;
  @each $color in $colors {
    &__#{$color} {
      background: var(--color-#{$color});
      color: var(--color-white);
      border: $button-border-width solid var(--color-#{$color});
      transition: $transition-fast;

      &:hover, &.is-transparent {
        background: transparent;
        color: var(--color-#{$color});
        border-color: var(--color-#{$color});
        transition: $transition-slow;
      }
    }
  }

  &__disabled,
  &__disabled:hover {
    background-color: var(--color-gray) !important;
    border-color: var(--color-gray) !important;
    color: var(--color-white);
    &:hover {
      background: var(--color-blue);
      color: var(--color-white);
    }
  }
}
