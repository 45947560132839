@use "src/styles/typography";

.footer {
  padding: 20px 46px;

  background: var(--gradient-blue);

  margin-bottom: 0;
  margin-top: auto;

  &--wrapper {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      gap: 18px;
    }

    justify-content: space-between;
  }

  &--info-section {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1220px) {
      align-items: center;
      text-align: center;
      gap: 18px;
    }

    gap: 2px;
  }

  &--definition-term {
    @include typography.page_subtitle;

    cursor: pointer;
    color: var(--color-white);

    margin-bottom: 6px;
  }

  &--definition-details {
    display: flex;

    align-items: center;
    margin-bottom: 6px;
    gap: 4px;

    @media screen and (max-width: 1220px) {
      justify-content: center;
      margin: 0 0 2px 0;
    }

    &--description {
      @include typography.ordinary_subtitle;

      text-decoration: none;
      cursor: pointer;
      color: var(--color-white);
    }
  }

  &--description-system-web {
    @extend .footer--definition-term;
    margin: auto 0 0 0;

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }
  &--description-system-mobile {
    @extend .footer--definition-term;
    margin: auto 0 0 0;

    @media screen and (min-width: 1220px) {
      display: none;
    }
  }

  &--logo-web {
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }
  &--logo-mobile {
    @media screen and (min-width: 1220px) {
      display: none;
    }
  }
}