@import "~@csstools/normalize.css";
@import "@/styles/css-variables.scss";
@import "@/styles/fonts";
@import "@/styles/typography";
@import "react-image-crop/dist/ReactCrop.css";

body {
  overflow-y: scroll;

  margin: 0;

  background-color: var(--color-gray-light);

  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
select,
textarea {
  font-family: "Rubik", sans-serif;
}

select,
button {
  cursor: pointer;

  font-style: normal;

  &:disabled {
    cursor: not-allowed;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
ul,
p {
  margin: 0;

  font-style: normal;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

#root {
  display: flex;

  flex-direction: column;

  min-height: 100vh;
}

.page-container {
  display: flex;
  margin-top: 56px;
  justify-content: center;
  padding: 22px 0;
  @media screen and (max-width: 1220px) {
    padding: 0;
  }
}

.container-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1040px;
  width: 100%;
  gap: 20px;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 70vh;
    gap: 0;
  }
}

.page-content-wrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 740px;
  box-sizing: border-box;

  gap: 20px;
  padding: 20px 40px;

  border-radius: 26px;
  background: var(--color-white);

  @media screen and (max-width: 1220px) {
    align-self: center;
    margin: 20px 0;
  }
}

.page-content-title {
  @include page_head;
}

.blue-star {
  svg,
  path {
    fill: none;
    stroke: var(--color-blue);
  }

  &.fill {
    svg,
    path {
      fill: var(--color-blue-dark);
    }
  }
}

.toaster {
  margin-top: 50px;
}
