@use "src/styles/typography";

.scroll {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  transition: 1s;
}

.header {
  position: fixed;
  background: var(--gradient-blue);
  z-index: 10;

  width: 100%;

  transition: 0.3s;

  &--wrapper {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    margin-left: auto;
    margin-right: auto;

    max-width: 1440px;
    padding: 10px 142px;

    @media screen and (max-width: 1220px){
      padding: 10px 20px;
    }
  }

  &--user {
    display: inline-flex;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    img, svg {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  &--change-block {
    display: flex;

    align-items: center;

    gap: 16px;
  }
}

.navigation {
  &--wrapper {
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }
  &--list {
    display: flex;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: start;
    }

    gap: 61px;
    margin: 0;
    padding: 0;

    list-style: none;

    @media screen and (max-width: 1220px) {
      gap: 0;

      li {
        padding: 16px 20px;
        width: calc(100% - 40px);
        border-bottom: 1px solid var(--color-gray-transparent);
      }

      li:hover {
        background: var(--color-gray-transparent);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &--item-link {
    @include typography.button_big;

    text-decoration: none;
    color: var(--color-white);

    @media screen and (max-width: 1220px) {
      @include typography.page_subtitle;
      color: var(--color-white);
    }
  }
}

.log-in {
  @extend .navigation--item-link;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 1220px) {
    content: url("../../static/images/log-in.svg");
    max-width: 24px;
  }
}

.language-block {
  display: flex;
  align-items: center;

  cursor: pointer;

  @media screen and (max-width: 1220px) {
    display: none;
  }
}

.mobile-menu-icon {
  display: none;

  @media screen and (max-width: 1220px) {
    display: block;

    cursor: pointer;

    path {
      stroke: var(--color-white);
      fill: var(--color-white);
    }
  }
}

.sidebar {
  position: fixed;

  max-width: 400px;
  width: 100%;
  height: 100vh;
  left: -400px;

  overflow: auto;

  transition: all 1s ease-in-out;

  z-index: 10;
  background: var(--gradient-blue);

  :root {
    background-color: var(--color-red);
  }
}
.sidebar.checked {
  transform: translate(400px, 0);

  body {
    overflow: hidden;
  }
}

.mobile-menu {
  @media screen and (min-width: 1220px) {
    display: none;
  }
}